import Vue from "vue";
import SpinnerMixin from "@/mixins/spinner";
import { mapGetters } from "vuex";
export default Vue.extend({
    mixins: [SpinnerMixin],
    data() {
        return {
            offices: [],
            officeId: null,
        };
    },
    computed: {
        ...mapGetters({ hasMultipleOffices: "account/hasMultipleOffices" }),
    },
    methods: {
        search() {
            this.$emit("search", {
                officeId: this.officeId
            });
        }
    },
    async mounted() {
        //this.showSpinner();
        try {
            this.offices = await this.$store.dispatch("office/getAll");
            (this.offices).unshift({
                id: null,
                name: this.$t('offices.all')
            });
        }
        catch (errors) {
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
        }
        finally {
            //this.hideSpinner()
        }
    }
});
