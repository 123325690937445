import Vue from "vue";
import { GridPlugin, Page, Filter, Sort, Toolbar, Search, Edit, CommandColumn } from "@syncfusion/ej2-vue-grids";
import { CustomUrlAdaptor } from "@/syncfusion/CustomUrlAdaptor";
import { DataManager } from "@syncfusion/ej2-data";
import SearchToolbar from "@/components/Templates/CalendarType/SearchToolbar.vue";
import titleMixin from "@/mixins/title";
import gridMixin from "@/mixins/syncfusion/grid";
import { mapGetters } from "vuex";
Vue.use(GridPlugin);
export default Vue.extend({
    components: { "search-toolbar": SearchToolbar },
    mixins: [gridMixin, titleMixin],
    data() {
        return {
            title: this.$t("calendarTypes.calendarTypes"),
            data: new DataManager({
                url: process.env.VUE_APP_API_BASE_URL + "calendar-types/grid-data",
                //insertUrl: process.env.VUE_APP_API_BASE_URL + "calendar-types/grid-create",
                //updateUrl: process.env.VUE_APP_API_BASE_URL + "calendar-types/grid-edit",
                removeUrl: process.env.VUE_APP_API_BASE_URL + "calendar-types/grid-delete",
                adaptor: new CustomUrlAdaptor()
            }),
            filterOptions: { type: "Menu" },
            pageSettings: { pageSize: 10 },
            toolbarOptions: [
                {
                    text: this.$t("shared.add"),
                    tooltipText: this.$t("shared.add"),
                    prefixIcon: "e-add",
                    id: "Custom_Add"
                },
                "Search"
            ],
            sortOptions: {
                columns: [
                    { field: "OfficeName", direction: "Ascending" },
                    { field: "Name", direction: "Ascending" }
                ]
            },
            searchOptions: {
                fields: ["Name"],
                operator: "contains"
            },
            editSettings: {
                allowEditing: false,
                allowAdding: false,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog"
            },
            commands: [
                {
                    type: "Custom_Edit",
                    title: this.$t("shared.edit"),
                    buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                },
                {
                    type: "Delete",
                    buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                }
            ]
        };
    },
    provide: {
        grid: [Page, Filter, Sort, Toolbar, Search, Edit, CommandColumn]
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices"
        })
    },
    methods: {
        search(args) {
            const grid = this.$refs["calendar-types-grid"];
            const gridInstance = grid.ej2Instances;
            const filters = [];
            // Add grid filters
            for (const column of gridInstance.filterSettings.columns) {
                if (column.field != undefined && !["OfficeId"].includes(column.field))
                    filters.push(column);
            }
            // Add advanced filters
            if (args.officeId != undefined)
                filters.push({ field: 'OfficeId', matchCase: false, operator: 'equal', predicate: 'and', value: args.officeId });
            // Set filters and refresh grid
            gridInstance.filterSettings.columns = filters;
            grid.refresh();
        },
        toolbarClick(args) {
            if (args.item.id == "Custom_Add") {
                this.$router.push("/administration/calendar-types/create");
                return;
            }
        },
        commandClick(args) {
            if (args.commandColumn.type === "Custom_Edit") {
                this.$router.push("/administration/calendar-types/" + args.rowData.Id + "/edit");
                return;
            }
        },
        actionComplete(args) {
            if (args.requestType === "delete") {
                this.$toast.showSuccessToast(this.$t("calendarTypes.delete.toastSuccessTitle"), this.$t("calendarTypes.delete.toastSuccessContent", {
                    name: args.data[0].Name
                }));
            }
        }
    }
});
